
.loader_Bg {
    align-items: center;
    background: rgba(0, 0, 0, 0.88);
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
    flex-direction: column;
  }
  
  .loader_Bg #sparkles path {
    fill: #fff;
  }
  
#sparkles > path {
    animation: sparklyBits 1000ms infinite;
    position: absolute;
  }
  
  #sparkles > path:nth-child(1) {
    animation-delay: 35ms;
  }
  
  #sparkles > path:nth-child(2) {
    animation-delay: 70ms;
  }
  
  #sparkles > path:nth-child(3) {
    animation-delay: 105ms;
  }
  
  #sparkles > path:nth-child(4) {
    animation-delay: 140ms;
  }
  
  #sparkles > path:nth-child(5) {
    animation-delay: 175ms;
  }
  
  #sparkles > path:nth-child(6) {
    animation-delay: 210ms;
  }
  
  #sparkles > path:nth-child(7) {
    animation-delay: 245ms;
  }
  
  #sparkles > path:nth-child(8) {
    animation-delay: 280ms;
  }
  
  #sparkles > path:nth-child(9) {
    animation-delay: 315ms;
  }
  
  #sparkles > path:nth-child(10) {
    animation-delay: 350ms;
  }
  
  #sparkles > path:nth-child(11) {
    animation-delay: 385ms;
  }
  
  #sparkles > path:nth-child(12) {
    animation-delay: 420ms;
  }
  
  #sparkles > path:nth-child(13) {
    animation-delay: 455ms;
  }
  
  #sparkles > path:nth-child(14) {
    animation-delay: 490ms;
  }
  
  #sparkles > path:nth-child(15) {
    animation-delay: 525ms;
  }
  
  #sparkles > path:nth-child(16) {
    animation-delay: 560ms;
  }
  
  #sparkles > path:nth-child(17) {
    animation-delay: 595ms;
  }
  
  #sparkles > path:nth-child(18) {
    animation-delay: 630ms;
  }
  
  #sparkles > path:nth-child(19) {
    animation-delay: 665ms;
  }
  
  #sparkles > path:nth-child(20) {
    animation-delay: 700ms;
  }
  
  #sparkles > path:nth-child(21) {
    animation-delay: 735ms;
  }
  
  #sparkles > path:nth-child(22) {
    animation-delay: 770ms;
  }
  
  #sparkles > path:nth-child(23) {
    animation-delay: 805ms;
  }
  
  #sparkles > path:nth-child(24) {
    animation-delay: 840ms;
  }
  
  #sparkles > path:nth-child(25) {
    animation-delay: 875ms;
  }
  
  #sparkles > path:nth-child(26) {
    animation-delay: 910ms;
  }
  
  #sparkles > path:nth-child(27) {
    animation-delay: 945ms;
  }
  
  #sparkles > path:nth-child(28) {
    animation-delay: 980ms;
  }
  
  #sparkles > path:nth-child(29) {
    animation-delay: 1015ms;
  }
  
  .loader_Bg {
    align-items: center;
    background: rgba(0, 0, 0, 0.88);
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
    flex-direction: column;
  }
  
  .loader_Bg #sparkles path {
    fill: #fff;
  }
  
  #burrito #Ellipse_40,
  #burrito #Ellipse_35 {
    fill: #00ff78;
  }
  
  #Line_18,
  #Line_19 {
    fill: #fff;
    stroke: #97bcff;
  }
  
  #Rectangle_42 {
    fill: #062252;
  }
  
  #Path_11,
  #Path_12 {
    fill: #ffffff;
  }
  
  #Ellipse_41,
  #Ellipse_38 {
    fill: #000;
    stroke: #fff;
    stroke-width: 1px;
  }
  #Rectangle_37,
  #Rectangle_38,
  #Rectangle_39 {
    stroke: #4185f4;
  }
  .loader_Bg #message {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    margin-top: 12px;
    text-align: center;
    letter-spacing: 6px;
  }
  #Ellipse_41,
  #Ellipse_38 {
    animation: circle 1s alternate infinite ease;
  }
  #Path_12 {
    animation: sparklyBits 1000ms alternate infinite ease;
  }
  #Path_10 {
    animation: face 1000ms alternate infinite ease;
  }
  #Path_11 {
    animation: sparklyBits 1000ms alternate infinite ease;
  }
  
  @keyframes sparklyBits {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes powerlight {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes circle {
    0% {
      ry: 0.601;
    }
    40% {
      ry: 2.601;
    }
  }
  @keyframes face {
    0% {
      stroke-width: 0;
    }
    40% {
      stroke-width: 3;
    }
  }
  
.mainLoaderNew {
    font-size: 30px;
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    left: 0;
  }

  .mainLoaderNew.opacity {
    background: rgba(255, 255, 255, 0.5);
  }
  
  .loadingNew {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .textLoding {
    color: #4092fb;
    display: inline-block;
    margin-left: 10px;
    font-size: 20px;
    font-family: "Nunito";
  }
  
  .bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: 15px;
  }
  
  .bounceball::before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #4092fb;
    transform-origin: 50%;
    animation: bounce 500ms alternate infinite ease;
  }
  
  @keyframes bounce {
    0% {
      top: 30px;
      height: 5px;
      border-radius: 60px 60px 20px 20px;
      transform: scaleX(2);
    }
    35% {
      height: 15px;
      border-radius: 50%;
      transform: scaleX(1);
    }
    100% {
      top: 0;
    }
  }