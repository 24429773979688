

.box{
    max-width: 300px;
    width: 100%;
    padding: 0 15px;
}
.skeleton {
    padding:25px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12);
}
.skeleton .square {
    height: 80px;
    border-radius: 5px;
    background: rgba(130, 130, 130, 0.2);
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation: wave-squares 2s infinite ease-out;
}
.skeleton .line {
    height: 12px;
    margin-bottom:6px;
    border-radius: 2px;
    background: rgba(130, 130, 130, 0.2);
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation: wave-lines 2s infinite ease-out;
}
.skeleton-right{
   flex:1;
}
.skeleton-left{
   flex:2;
   padding-right:15px;
}
.flex1{
   flex: 1;
}
.flex2{
   flex: 2;
}
.skeleton .line:last-child{
   margin-bottom: 0;
}
.h8{
   height: 8px !important;
}
.h10{
   height: 10px !important;
}
.h12{
   height: 12px !important;
}
.h15{
   height: 15px !important;
}
.h17{
   height: 17px !important;
}
.h20{
   height: 20px !important;
}
.h25{
   height: 25px !important;
}
.w25{
   width: 25% !important
}
.w40{
   width:40% !important;
}
.w50{
   width: 50% !important
}
.w75{
   width: 75% !important
}
.m10{
   margin-bottom: 10px !important;
}

@keyframes wave-lines {
   0% {
       background-position: -468px 0;
   }
    100% {
       background-position: 468px 0;
   }
}
@keyframes wave-squares {
   0% {
       background-position: -468px 0;
   }
    100% {
       background-position: 468px 0;
   }
}
