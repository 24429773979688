.flow-chart-skeleton {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .step-skeleton {
    width: 500px;
    height: 50px;
    background-color: #f0f0f0;
    margin: 10px 0;
    border-radius: 4px;
    animation: loading 1s infinite ease-in-out;
  }
  .arrow-skeleton {
    position: relative;
    width: 20px; /* Adjust width as needed */
    height: 60px; /* Adjust height as needed */
    background-color: #f0f0f0;
    margin: 10px 0;
    
  }
  
  
  
  
  @keyframes loading {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
  