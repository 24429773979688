@tailwind base;
@tailwind components;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;

}
.bg-bodyBG{
  /* background-color: #F5F6FA !important; */
}
input:checked ~ .dot {
    transform: translateX(150%);
    background-color: #fff;
  }
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  .boatEditSection .selected:focus {
    box-shadow: none !important;
}

  /* calendar css start */
  .wrapperLefts .rbc-toolbar{
    margin-bottom: 2rem;
    gap: 1rem;
}
.rbc-month-view .rbc-header{
    font-weight: 400;
    color: #343434;
    padding: 15px 10px;
}
.wrapperLefts .rbc-toolbar .rbc-btn-group button{
    border-radius: 18px;
    border: 0;
    color: #343434;
    font-weight: 400;
    font-size: 12px;
    padding: 1rem 1.5rem;
    background-color: #fff;
}
.wrapperLefts .rbc-toolbar button:active, .wrapperLefts .rbc-toolbar button.rbc-active, .wrapperLefts .rbc-toolbar button:hover, .wrapperLefts .rbc-toolbar button:focus, .wrapperLefts .rbc-toolbar button:active:hover, .wrapperLefts .rbc-toolbar button:active:focus, .wrapperLefts .rbc-toolbar button.rbc-active:hover, .wrapperLefts .rbc-toolbar button.rbc-active:focus {
    background-color: #5371FF;
    color: #fff;
}
 .wrapperLefts .rbc-toolbar-label{
    color: #2E4765;
    font-size: 22px;
}
.wrapperLefts .rbc-toolbar .rbc-btn-group > button:nth-child(2) {
    border-right: 2px solid #ddd;
    border-left: 1px solid #ddd;
}
.wrapperLefts .rbc-date-cell{
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    font-weight: 300;
}
.wrapperLefts .rbc-toolbar > .rbc-btn-group:first-child{
  /* display: none; */
}
.wrapperLefts .rbc-toolbar .rbc-toolbar-label{
  text-align: start;
}
.conectionIntegrationBox {
  gap: 1rem;
}
#heatmap{
  overflow-x: auto;
}
@media(max-width: 767px){
  .wrapperLefts .rbc-date-cell{
      padding: 10px;
  }
  .wrapperLefts .rbc-toolbar .rbc-toolbar-label{
    text-align: center;
  }
}

  /* css end */
 .dropdown-list {
    @apply text-[12px] before:absolute before:right-4 before:-top-[9px] before:w-4 before:h-4 before:bg-white before:rotate-45 before:border-[#ccc] before:border-l before:border-t dropdown-menu absolute bg-white border rounded-xl top-7 -right-[16px] w-40 text-gray-700 pt-1 shadow-xl;
  }
  
  .hover-div {
    @apply hover:bg-[#F2F8FF] hover:text-[#5371FF] rounded-xl
  }


  .pulse-animation {
    @apply p-[10px] rounded-full border-2 border-[#5269c9] bg-white transition-all relative flex items-center justify-center text-thmColor w-5 h-5 cursor-pointer 
    before:content-[''] before:absolute before:-top-[2px] before:-right-[2px] before:-bottom-[2px] before:-left-[2px] before:bg-[#5269c9] before:rounded-full before:-z-[1]
    
  }
  
  
.selected_menu {
    appearance: none;
    background-image: url('../src/img/up-down.svg');
    background-repeat: no-repeat;
    background-position: right 14px top 14px;
}
.boatEditSection .selected {
  box-shadow: none !important;
}
/* .react-flow__node.react-flow__node-default.nopan.selected.selectable {
  background: none;
} */
.transition-cls{
  -webkit-transition: width .3s ease-in-out;
  transition: width .3s ease-in-out;
}
#sidebarMenu,.mobile-transition, .mobileClassleft, .arrowdown {
  -webkit-transition: all .4s ease;
  -ms-transition: all .4s ease;
  transition: all .4s ease;
}
.off-menu ul.w-full li div span, .off-menu div span, .off-menu ul.w-full h3.heading {
    display: none;
    opacity: 0;
}
.off-menu .menubottom >li>a h3 {
  justify-content: center;
}
.off-menu .menubottom >li{
  padding-left: 0;
}
.off-menu {
  width: 80px !important;
}
.off-menu  .logo-details{
  justify-content: start;
}
/* .mob-p-0 h3 {
  justify-content: center;
} */
.off-menu img.invisible {
  visibility: hidden;
  opacity: 0;
  display: none;
}
#sidebarMenu li{
  transition: all .4s ease;
}
.link_name{
  transition: all .4s ease;
  white-space: pre;
}
.off-menu .link_name{
  opacity: 0;
  pointer-events: none;
}
.off-menu .menuIcon {
  @apply top-3
}
.off-menu .arrowBack {
  justify-content: center;
  transform: rotate(180deg);
  border-top: none;
  border-bottom: 1px solid #fff;
}
.off-menu + div {
  padding-left: 100px;
  transition: all .4s ease;
}
.logo-details > img {
  transition: all .4s ease;
  transform-origin: left top;
}
.bx-icon{
  transition: all .4s ease;
}
.cstm-swiper {
  position: relative;
}
.cstm-swiper .swiper-pagination {
  position: absolute;
  top: 7px;
  font-size: 12px;
  width: 50px;
  right: 130px;
  left: auto;
  height: 20px;
}

.cstm-swiper .swiper {
  padding-top: 50px
}
.cstm-swiper .swiper-button-prev, .cstm-swiper .swiper-button-next {
  position: absolute;
  top: 22px;
  right: 112px;
  left: auto;
  z-index: 99;
  height: 31px;
}
.cstm-swiper .swiper-button-next {
  right: 95px;
}

.cstm-swiper .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after, 
.cstm-swiper .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  font-size: 15px;
}
.cstm-swiper.follow-node .swiper-button-prev {
  left: 2px !important;
}
.cstm-swiper.follow-node .swiper-button-next, .cstm-swiper.follow-node .swiper-button-prev {
  right: -2px;
  left: auto;
  background: #EDF1FF;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 68%;
}
.cstm-swiper.follow-node .swiper-button-next:after, .cstm-swiper.follow-node .swiper-button-prev:after {
  font-size: 12px;
}

/* css of old preview start */


/* css of old preview end */


/* 
.mainLoaderNew {
  font-size: 30px;
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  left: 0;
}
.mainLoaderNew1 {
  font-size: 30px;
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
   background: #fff;
  top: 0;
  left: 0;
}
.mainLoaderNew.opacity {
  background: rgba(255, 255, 255, 0.5);
}

.loadingNew {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.textLoding {
  color: #4092fb;
  display: inline-block;
  margin-left: 10px;
  font-size: 20px;
  font-family: "Nunito";
}

.bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 15px;
}

.bounceball::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #4092fb;
  transform-origin: 50%;
  animation: bounce 500ms alternate infinite ease;
}

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
} */

.h-calc-cstm-bot {
  height: calc(100% - 50px) !important;
}
.speech.on.btn {
  border: none;
  padding: 0;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  font-size: 3em;
  color: #fff;
  padding: 0;
  margin: 0;
  background: #189BFF;
  position: relative;
  z-index: 999;
  display: inline-flex;
  line-height: 100px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  background-image: none;
  top: -5px;
  right: -1px;
}
.speech.on.btn > svg path {
  stroke: white;
}

.speech.on .pulse-ring {
  content: "";
  width: 45px;
  height: 45px;
  background: #189BFF;
  border: 8px solid #189BFF;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  left: -3px;
  animation: pulsate infinite 1.5s;
}
.speech.on .pulse-ring {
  background: transparent;
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
  
  100% {
    -webkit-transform: scale(1.3, 1.3);
    opacity: 0;
  }
}
.editorBtn> button {
  border-radius: 25px;
}
.btnround> button{
  border-radius: .25rem;
}
.editorBody .editorView input[type="checkbox"] {
 width: 18px;
 height: 18px;
 vertical-align: middle;
}

/* // LOADER FOR CHATBOT AI  */

.loder-view li {
  padding: 15px 0;
  display: flex;
  position: relative;
  align-items: center;
}

.loder-view li .circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 20px;
  background: #D6D6D6;
  position: relative;
  animation: fade-in-circle 0.5s forwards;
}

.loder-view li .circle::after,
.loder-view li .circle::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #D6D6D6;
  left: -9px;
  top: -9px;
}

.loder-view li .circle::before {
  animation: fade-circle 0.5s forwards;
}

.loder-view li .line::before,
.loder-view li .line::after {
  content: "";
  position: absolute;
  background: #D6D6D6;
  width: 3px;
  height: 42px;
  top: 40px;
  left: 5px;
}

.loder-view li .line::after {
  height: 0;
  animation: fade-in 4s forwards;
}

.loder-view li:last-child .line::after,
.loder-view li:last-child .line::before {
  display: none;
}

.loder-view li:nth-child(1) .circle {
  animation-delay: 1s;
}

.loder-view li:nth-child(1) .circle::before {
  z-index: 1;
  animation-delay: 4s;
}

.loder-view li:nth-child(1) .line::after {
  background: #2967F6;
  animation-delay: 5s;
}

.loder-view li:nth-child(2) .circle {
  animation-delay: 10s;
}

.loder-view li:nth-child(2) .circle::before {
  z-index: 1;
  animation-delay: 8s;
}

.loder-view li:nth-child(2) .line::after {
  background: #2967F6;
  animation-delay: 12s;
}

.loder-view li:nth-child(3) .circle {
  animation-delay: 17s;
}

.loder-view li:nth-child(3) .circle::before {
  z-index: 1;
  animation-delay: 16s;
}

.loder-view li:nth-child(3) .line::after {
  background: #2967F6;
  animation-delay: 18s;
}

.loder-view li:nth-child(4) .circle {
  animation-delay: 23s;
}

.loder-view li:nth-child(4) .circle::before {
  z-index: 1;
  animation-delay: 21.5s;
}

.loder-view li:nth-child(4) .line::after {
  background: #2967F6;
  animation-delay: 25s;
}

.loder-view li:nth-child(5) .circle {
  animation-delay: 29s;
}

.loder-view li:nth-child(5) .circle::before {
  z-index: 1;
  animation-delay: 28s;
}

.loder-view li:nth-child(5) .line::after {
  background: #2967F6;
  animation-delay: 29s;
}

.loder-view li:nth-child(6) .circle {
  animation-delay: 35s;
}

.loder-view li:nth-child(6) .circle::before {
  z-index: 1;
  animation-delay: 34s;
}

.loder-view li:nth-child(6) .line::after {
  background: #2967F6;
  animation-delay: 38s;
}

.loder-view li:nth-child(7) .circle {
  animation-delay: 44s;
}

.loder-view li:nth-child(7) .circle::before {
  z-index: 1;
  animation-delay: 43s;
}

.loder-view li:nth-child(7) .line::after {
  background: #2967F6;
  animation-delay: 70s;
}

.loder-view li:nth-child(8) .circle {
  animation-delay: 102s;
}

.loder-view li:nth-child(8) .circle::before {
  z-index: 1;
  animation-delay: 100s;
}


@keyframes fade-in-circle {
  0% {
     background-color: #D6D6D6;

  }

  100% {
     background: #2967F6;
  }
}

@keyframes fade-circle {
  0% {
     border-color: transparent;
  }

  100% {
     border-color: #2967F6;
  }
}

@keyframes fade-in {
  0% {
     height: 0px;
  }

  100% {
     height: 42px;
  }
}

.bot-img {
  width: 100%;
  max-width: 300px;
  filter: grayscale(100%);
  transition: filter 100s;
  animation: bot-color 40s forwards;
}


.rounded-header .mainBoat, .rounded-header #mainView {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}





@keyframes bot-color {
  0% {
     filter: grayscale(100%);
  }

  100% {
     filter: none;

  }
}

.bot-view {
  animation: transform 1s alternate infinite ease-in;
}

@keyframes transform {
  0% {
     transform: scale(1);
  }

  100% {
     transform: scale(1.03);
  }
}

@media (max-width:1023px) {
  .sidemenu{
    width:255px !important;
    left: -100% !important;
    z-index: 25 !important;
  }
  .sidemenu::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 70%);
    top: 0;
    left: 0;
    z-index: 1;
    -webkit-transition: opacity .4s linear;
    transition: opacity .4s linear;
    opacity: 0;
    visibility: hidden;
}
  .showsidemenumobile::before {
    opacity: 1;
    visibility: visible;
    left: 255px;
  }
  .sidemenu li{
    padding-left: 0;
  }
  .link_name{
    display: inline-block;
  }
  .logo-details > img{
    display: none;
    visibility: hidden;
  }
  .showsidemenumobile{
    left: 0% !important;
  }
  .off-menu + div{
    padding-left: 1.25rem;
  }
  .off-menu .link_name , .off-menu ul.w-full li div span, .off-menu div span, .off-menu ul.w-full h3.heading{
    opacity: 0;
    pointer-events: auto;
    display: none;
}
.off-menu .link_name, .off-menu ul.w-full li div span, .off-menu div span{
  opacity: 1;
  pointer-events: auto;
  display: inline-block;

}
.off-menu img.invisible {
  visibility: hidden;
  opacity: 0;
  display: none;
}
.showsidemenumobile + .mobileClass>* {
  left: 0;
  z-index: 30;
  /* width: 255px; */
  /* padding-left: 0 ; */
}

/* 
.mobileClassleft {
  padding-left: 20px !important;
  left: 0;
  width: 255px;
} */


.false + .mobileClass >* {
  left: 0;
  /* padding-left: 0 !important; */
}
.heading-mobile {
  width: 80%;
}
.heading-mobile-wrap {
  width: 100%;
  text-align: center;
}
.heading-mobile-wrap h1{
  font-size: 18px;
}
.flex-0{
  flex: 0 0 auto;
}
.shadow-mobile{
  box-shadow: rgb(220 235 250 / 80%) 0px -3px 16px;
}
.border-middle::after{
  content: "";
  background: #d5d5d5;
    width: 1.25px;
    height: 70%;
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
}
.mobile-view{
  height: auto !important;
    padding: 0 !important;
    box-shadow: none !important;
    background: transparent !important;
    margin-top: -1.25rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
}
.mobile-view .boatEditSection {
  background: none;
  padding-top: 0;
}
.mobile-code>span {
  text-decoration: none;
}
.mobile-code {
  background: #F3F5FF;
  padding: 12px;
  border-radius: 6px;
  display: inline-block;
}
.mobile-copy{
  top: -60px !important;
  right: auto !important;
  left: 0;
  background: #F3F5FF !important;
  padding: 12px;
  display: inline-flex !important;
  width: auto !important;
  height: auto !important;
}
.codeWidget{
  margin-top: 5rem !important;
}
.active.arrowright {
  -webkit-transform: rotate(90deg);
}
button.active > .arrowright {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
}
@media (max-width:767px) {
  body{font-size: .85rem;}
  .bot-img {
    display: none;
  }
  .loder-view {
    max-width: 350px;
    margin: 0 auto;
}
.customeWebsiteWidget {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.watchGuideVideo > iframe {
  height: 180px;
}
.deployBoatInWebsiteRow {
  flex-direction: column;
}
.deployBoatInWebsiteRow > .deployeWebsiteLink, .deployBoatInWebsiteRow > .deployeWebsiteDetails{
  width: 100%;
}
.dropdown-list{
  right: 0;
}
.widgetNewAccordingSelction::after{
  display: none !important;
}
.rdrDateRangePickerWrapper > .rdrDefinedRangesWrapper{
  width: 100%;
}
.rdrCalendarWrapper{
  width: 285px;
}
svg.frappe-chart.chart{
  height: 300px;
}
#pastpresentcp, canvas#allbotfb, canvas#pastpresentfb{
  margin: 1rem 0;
}
}
@media (max-width: 480px) {
  
  .loder-view {
    padding-left: 30px;
}
button.active {
  border-color: #5973F7;
}
}


.sticky{
  position: sticky;
}
.widgetNewAccordingSelction::after {
  content: "";
  position: absolute;
  background: url('./img/mobile-img.png') no-repeat center;
  width: 420px;
  height: 690px;
  display: block;
  top: -70px;
  left: -50px;
  right: 0;
  margin: 0 auto;
  background-size: 100% 100%;
  z-index: -1;
}
.shadow-cstm {
  box-shadow: 0px 0px 7px 2px lightgrey;
}

.react-tabs .react-tabs__tab--selected{
  color: #8B8B8B;
  border-bottom: 4px solid #5269C9;
}
.configure-tabs .react-tabs__tab--selected {
  border-bottom: 0;
  background: #fff;
  color: #5371ff;
  font-weight: 600;
}
.starAnimaton{
  width: 60px;
  position: absolute;
  height: 40px;
  top: 5px;
  right: 15px;
}
.starA {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.9em;
  margin-right: 0em;
  margin-bottom: 1.2em;
  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #df8d33;
  border-left: 0.3em solid transparent;
  font-size: 16px;
  transform: scale(0.4);
  animation: star 0.5s ease infinite alternate;
}
.starAnimaton i:nth-child(1) {
  position: absolute;
  top: 0;
  left: 15px;
}
.starAnimaton i:nth-child(2) {
  position: absolute;
  right: 0;
  top: 0;
  animation-delay: 0.5s;
}
.starAnimaton i:nth-child(3) {
  position: absolute;
  bottom: -12px;
  right: 2px;
  animation-delay: 1s;
}
.starA:before, .starA:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 0.6em;
  left: -1em;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #df8d33;
  border-left: 1em solid transparent;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
}
.starA:after {
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}
@keyframes star{
  0% {
    transform: scale(0.4);
  }
  
  100% {
    transform: scale(0.5);
  }
}
.rdw-link-modal{
  height: 240px !important;
}
.rdw-editor-wrapper {
    border: 1px solid rgb(216 216 216);
    border-radius: 0.5rem;
    background-color: #f2f8ff;
    padding: 0.5rem;
}
.DraftEditor-root{
  padding: 0.25rem .75rem;
  border: 1px solid #F1F1F1;
  border-radius: 0.25rem;
}
label.rdw-link-modal-target-option > span {
  font-size: .9rem;
}
.react-flow__node-default{
  border-radius: 0.75rem !important;
}


.clientele-section ul li img {
  height: 43px;
  filter: grayscale(1);
  opacity: 0.4;
  transition: all 0.4s ease-in-out;
}
@media(max-width: 767px){
  .clientele-section ul li img {
    height: 20px;
}
}
.active .arrowdown {
  transform: rotate(180deg);
}

@media (min-width: 1900px) {
  .xxl{
    padding-top:10rem !important;
  }
}

@tailwind utilities;
.active-bg {
  @apply bg-[#F7FBFF] text-[#5371FF] rounded-xl
}


.yellowAlert {
  background: #fdf9e9;
  padding: 20px;
  border-radius: 20px;
}
.yellowAlert span {
  font-size: 13px;
  font-weight: 500;
  color: #7C652D;
  line-height: 23px;
}
.yellowAlert p {
  font-size: 12px;
  line-height: 21px;
  color: #7C652D;
}


.iframe-chatwidget {
  margin-top: 85px;
  height: calc(100vh - 155px);
}
.badgesize  svg {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.badgesize.active svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}



.w-full.md\:w-4\/12.footer>div {
  width: 100%;
}




.disable {
  opacity: .6;
  pointer-events: none;
}

.min-h-screen-calc{
  min-height: calc(100vh - 10vh);
}
.shadow-theme{
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.12);
}
.shadow-theme-1{
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
}
.shadow-theme-8{
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
}
@media(max-width: 1400px){
  .mobile-h {height: 430px !important;}
}
.svg-WH svg{
  width: 15px;
  height: 15px;
}
.scrollbar{
  scroll-behavior: smooth;
  scrollbar-width: none;
}
.boatEditSection, .card-node-wrap  {
  overflow-y: auto;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
  scrollbar-width: none;
}
.card-node-wrap {
  height: calc(-10vh + 80vh);
}
.cardSwiper .swiper-button-prev, .cardSwiper .swiper-button-next {
  width: 25px;
  height: 25px;
  top: 24px;
  background: #fff;
  border-radius: 100px;
  border: 1px solid #DBDBDB;
  left: auto;
  right: 145px;
}
.cardSwiper .swiper-button-next {
  right: 105px;
  left: auto;
}
.cardSwiper .swiper-button-prev:after, 
.cardSwiper .swiper-button-next:after {
  font-size: 11px;
  color: #000;
}

.css-1u9des2-indicatorSeparator{
  display: none;
}
.css-1xc3v61-indicatorContainer{
  color: #1d1d1d !important;
}
.css-13cymwt-control {
  border-color: #DBDBDB !important;
  border-radius: 0.375rem !important;
  min-height: 48px !important;
}
.css-tj5bde-Svg{
  -webkit-transition: transform .3s ease;
  transition: transform .3s ease;
}
.css-t3ipsp-control{
  min-height: 48px !important;
}
.css-t3ipsp-control:hover{
	border-color: #DBDBDB !important;
}
.css-t3ipsp-control svg{
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.css-1hkt69c-menu{
	cursor: pointer;
}
.css-1hkt69c-menu:hover{
	color: #5371FF !important;
}
.chatwrap {
  height: calc(100vh - 24vh);
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior: contain;
}
.chatwrap.chatlist {
  height: calc(100vh - 24vh);
}
@media(max-width: 1600px){
  .chatwrap {
    height: calc(100vh - 40vh);
  } 
}
.rotate-animation {
  animation: rotate 3s linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.notification-container{
  top: 70px !important;
}
.notification{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 15px !important;
}
.notification-success {
  border: 1px solid #29A75B !important;
  background-color: #EFFDE6 !important;
  color: #8CB47B !important;
  line-height: 24px !important;
  opacity: 1 !important;
  box-shadow: none !important;
}
.notification-error{
  color: #BD4343 !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  border: 1px solid #BD4343 !important;
  background: #FDE6E6 !important;
  opacity: 1 !important;
  box-shadow: none !important;
}
.notification-error h4, .notification-success h4{
  font-weight: 500 !important;
  margin-bottom: 0px !important;
}

.notification-warning{
  color: #8A8585 !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  opacity: 1 !important;
  box-shadow: none !important;
  border: 1px solid #B4B4B4 !important;
  background: #EDEEF1 !important;
}
.notification-container{
  width: auto !important;
}

.advance-btn {
  margin-right: 1rem;
}

.boatEditSection.w-\[64\%\] .advance-btn {
  margin-right: 0;
}

.slider-progress[type="range"] {
    color: #5873F7;
	font-size: 1.155em;
    position: relative;
    background: transparent;
    overflow: hidden;
    --thumb-height: 1.155em;
    --track-height: 0.155em;
    --track-color: #ECEFFF;
    --brightness-hover: 120%;
    --brightness-down: 80%;
    --clip-edges: 0.155em;
}

.slider-progress[type="range"], 
.slider-progress[type="range"]::-webkit-slider-runnable-track, 
.slider-progress[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-transition: all ease-in-out .2s;
    transition: all ease-in-out .2s;
    height: 1.155em;
}

.slider-progress[type="range"]::-webkit-slider-runnable-track,
.slider-progress[type="range"]::-webkit-slider-thumb {
  	position: relative;
}

.slider-progress[type="range"]::-webkit-slider-thumb {
	--thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
	--clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
	--clip-bottom: calc(var(--thumb-height) - var(--clip-top));
	--clip-further: calc(100% + 1px);
	--box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;
	width: var(--thumb-width, var(--thumb-height));
	background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px);
	background-color: currentColor;
	box-shadow: var(--box-fill);
	border-radius: var(--thumb-width, var(--thumb-height));
	filter: brightness(100%);
	clip-path: polygon( 100% -1px, var(--clip-edges) -1px, 0 var(--clip-top), -100vmax var(--clip-top), -100vmax var(--clip-bottom), 0 var(--clip-bottom), var(--clip-edges) 100%,var(--clip-further) var(--clip-further));
}

.slider-progress[type="range"]:hover::-webkit-slider-thumb {
	filter: brightness(var(--brightness-hover));
	cursor: grab;
}

.slider-progress[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px);
}


/* === Firefox specific styles === */
.slider-progress[type="range"],
.slider-progress[type="range"]::-moz-range-track,
.slider-progress[type="range"]::-moz-range-thumb {
	appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

.slider-progress[type="range"]::-moz-range-track,
.slider-progress[type="range"]::-moz-range-thumb,
.slider-progress[type="range"]::-moz-range-progress {
	background: #fff0;
}

.slider-progress[type="range"]::-moz-range-thumb {
	background: currentColor;
	border: 0;
	width: var(--thumb-width, var(--thumb-height));
	border-radius: var(--thumb-width, var(--thumb-height));
	cursor: grab;
}

.slider-progress[type="range"]:active::-moz-range-thumb {
	cursor: grabbing;
}

.slider-progress[type="range"]::-moz-range-track {
	width: 100%;
	background: var(--track-color);
}

.slider-progress[type="range"]::-moz-range-progress {
	appearance: none;
	background: currentColor;
	transition-delay: 30ms;
}

.slider-progress[type="range"]::-moz-range-track,
.slider-progress[type="range"]::-moz-range-progress {
	height: calc(var(--track-height) + 1px);
	border-radius: var(--track-height);
}

.slider-progress[type="range"]::-moz-range-thumb,
.slider-progress[type="range"]::-moz-range-progress {
	filter: brightness(100%);
}

.slider-progress[type="range"]:hover::-moz-range-thumb,
.slider-progress[type="range"]:hover::-moz-range-progress {
	filter: brightness(var(--brightness-hover));
}

.slider-progress[type="range"]:active::-moz-range-thumb,
.slider-progress[type="range"]:active::-moz-range-progress {
	filter: brightness(var(--brightness-down));
}

.progress-overlap{
    height: 4px;
    display: block;
    position: absolute;
    top: 7px;
    left: 0px;
    background: linear-gradient(90deg, rgb(236, 239, 255) 0%, rgb(88, 115, 247) 100%);
    z-index: 1;
    border-radius: 2px
}
.sky-blue-loader {
  width: 50px;
  height: 50px;
  border: 5px solid #5d74e5a6;
  border-top: 5px solid #5371FF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 /* Adjust this value to set the distance from the top */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.disabled-btn {
  background-color: #f0f0f0;
  color: #a0a0a0;
  cursor: not-allowed;
  border-color: #d0d0d0;
}